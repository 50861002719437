import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          about: directusAboutTranslation(language: {eq: "it"}) {
              title
              pre_headline
              content
              seo_description
              seo_title
          }
          partners: allDirectusPartner {
              nodes {
                  link
                  logo {
                      localFile {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                          publicURL
                          extension
                      }
                  }
              }
          }
      }
  `)

  return (
    <Layout>
      <SEO title={ data.about.seo_title } description={ data.about.seo_description } lang={"it"} url={"/about"} />
      <Container fluid>
        <Row className={"site-content align-items-center"}>
          <Col md={10} className={"offset-md-1"}>
            <h4>{ data.about.pre_headline }</h4>
            <h1>{ data.about.title }</h1>
            <div className={"split-text"} dangerouslySetInnerHTML={{ __html:  data.about.content }}/>
            <h3>I nostri partner</h3>
            <Row className={"align-items-center justify-content-center"}>
              { data.partners.nodes.map((partner, key) => (
                <Col xs={4} sm={4} md={3} lg={2} key={key}>
                  <a href={partner.link}>
                    { partner.logo.localFile.extension === 'svg' ? (
                      <img src={partner.logo.localFile.publicURL} alt={partner.link}/>
                    ) : (
                      <Img fluid={partner.logo.localFile.childImageSharp.fluid}/>
                    )}
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
